import { createI18n } from 'vue-i18n'
import da from './json/da.json'
import de from './json/de.json'
import en from './json/en.json'
import es from './json/es.json'
import fi from './json/fi.json'
import frCA from './json/frCA.json'
import frFR from './json/frFR.json'
import it from './json/it.json'
import nl from './json/nl.json'
import no from './json/no.json'
import ptBR from './json/ptBR.json'
import ptPT from './json/ptPT.json'
import sv from './json/sv.json'

export const i18n = createI18n({
  locale: localStorage.getItem('locale') || 'en-US',
  globalInjection: true,
  legacy: false,
  runtimeOnly: true,
  fallbackLocale: 'en-US',
  messages: {
    ...en,
    ...es,
    ...de,
    ...frCA,
    ...frFR,
    ...it,
    ...nl,
    ...no,
    ...ptBR,
    ...ptPT,
    ...fi,
    ...sv,
    ...da,
  },
})
